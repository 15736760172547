export const user = (state = [], action) => { // (1)
    switch (action.type) { // (2)
        case 'FETCH_USER_SUCCESS':
            return {
                username: action.username,
                password: action.password
            }
        default:
            return state
    }
}