export const queue = (state = [], action) => { // (1)
    switch (action.type) { // (2)
        case 'SAVE_QUEUE_SUCCESS':
            return {
                queue: action.queue
            }
        default:
            return state
    }
}
