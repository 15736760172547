import React from 'react'
import './BatteryTooltip.css'
import * as d3 from "d3";

export default ({hoveredBar, proops, margin, width, height}) => {
    // const { xScale, yScale } = scales
    const xLabelHeight = 40;
    var data = proops.data;
    // var margin = proops.margin;


    var x = d3.scaleTime()
        .range([0, width - margin.left - margin.right]);

    var y = d3.scaleLinear()
        .range([height - margin.top - margin.bottom- xLabelHeight, 0]);

    // data.forEach(function(d) {
    x.domain(d3.extent(data, function (d) {
        //         // console.log(d.t)
        return d.t;
        //         // console.log("ggg-> " +timeFormat1)
        //         // return  d.t;
    }));
    y.domain([0, 100]);
    const styles = {
        left: `${Math.min(x(hoveredBar.t) - 10, width - 160)}px`,
        top: `${y((Number(hoveredBar.b) - 3050)/7.5)}px`
    }


    return (
        <div className="BatteryTooltip" style={styles}>
            <div>
                {Math.round((Number(hoveredBar.b) - 3050)/7.5*100)/100}% ({d3.timeFormat("%d %b %H:%M")(hoveredBar.t)})
            </div>
            <div>
                Napięcie na baterii: {Number(hoveredBar.b)/1000}[V]
            </div>
        </div>
    )
}
