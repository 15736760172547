import React from 'react';
import * as d3 from 'd3';

export default class Circle extends React.Component {


    // ref = createRef();
    conf = {
        minValue: 0, // The gauge minimum value.
        maxValue: 100, // The gauge maximum value.
        circleThickness: 0.05, // The outer circle thickness as a percentage of it's radius.
        circleFillGap: 0.05, // The size of the gap between the outer circle and wave circle as a percentage of the outer circles radius.
        circleColor: "#178BCA", // The color of the outer circle.
        waveHeight: 0.05, // The wave height as a percentage of the radius of the wave circle.
        waveCount: 1, // The number of full waves per width of the wave circle.
        waveRiseTime: 1000, // The amount of time in milliseconds for the wave to rise from 0 to it's final height.
        waveAnimateTime: 18000, // The amount of time in milliseconds for a full wave to enter the wave circle.
        waveRise: true, // Control if the wave should rise from 0 to it's full height, or start at it's full height.
        waveHeightScaling: true, // Controls wave size scaling at low and high fill percentages. When true, wave height reaches it's maximum at 50% fill, and minimum at 0% and 100% fill. This helps to prevent the wave from making the wave circle from appear totally full or empty when near it's minimum or maximum fill.
        waveAnimate: true, // Controls if the wave scrolls or is static.
        waveColor: "#178BCA", // The color of the fill wave.
        waveOffset: 0, // The amount to initially offset the wave. 0 = no offset. 1 = offset of one full wave.
        textVertPosition: .5, // The height at which to display the percentage text withing the wave circle. 0 = bottom, 1 = top.
        textSize: 1, // The relative height of the text to display in the wave circle. 1 = 50%
        valueCountUp: true, // If true, the displayed value counts up from 0 to it's final value upon loading. If false, the final value is displayed.
        displayPercent: true, // If true, a % symbol is displayed after the value.
        textColor: "#045681", // The color of the value text when the wave does not overlap it.
        waveTextColor: "#A4DBf8" // The color of the value text when the wave overlaps it.
    };

    // liquidFillGaugeDefaultSettings() {
    //
    //     return conf;
    // }

    loadLiquidFillGauge(gauge, value, config, elementId, bat, strength) {
        gauge.selectAll("*").remove();
        if (config == null) config = this.conf;
        let clientWidth = gauge.select(function() { return this.parentNode; })._groups[0][0].clientWidth ;
        let clientHeight = Math.min(470, Math.max(gauge.select(function() { return this.parentNode; })._groups[0][0].clientHeight -30, clientWidth));
        var radius = Math.min(clientWidth, clientHeight) / 2 - 20;
        var locationX = clientWidth / 2 - radius;
        var locationY = clientHeight / 2 - radius;
        var fillPercent = Math.max(config.minValue, Math.min(config.maxValue, value)) / config.maxValue;

        let waveHeightScale = null;
        if (config.waveHeightScaling) {
            waveHeightScale = d3.scaleLinear()
                .range([0, config.waveHeight, 0])
                .domain([0, 50, 100]);
        } else {
            waveHeightScale = d3.scaleLinear()
                .range([config.waveHeight, config.waveHeight])
                .domain([0, 100]);
        }

        const textPixels = (config.textSize * radius / 2);
        const textFinalValue = parseFloat(value).toFixed(2);
        const textStartValue = config.valueCountUp ? config.minValue : textFinalValue;
        const percentText = config.displayPercent ? "%" : "";
        const circleThickness = config.circleThickness * radius;
        const circleFillGap = config.circleFillGap * radius;
        const fillCircleMargin = circleThickness + circleFillGap;
        const fillCircleRadius = radius - fillCircleMargin;
        const waveHeight = fillCircleRadius * waveHeightScale(fillPercent * 100);

        const waveLength = fillCircleRadius * 2 / config.waveCount +circleThickness;
        const waveClipCount = 1 + config.waveCount;
        const waveClipWidth = waveLength * waveClipCount;

        // Rounding functions so that the correct number of decimal places is always displayed as the value counts up.
        let textRounder = function (value) {
            return Math.round(value);
        };
        if (parseFloat(textFinalValue) !== parseFloat(textRounder(textFinalValue))) {
            textRounder = function (value) {
                return parseFloat(value).toFixed(1);
            };
        }
        if (parseFloat(textFinalValue) !== parseFloat(textRounder(textFinalValue))) {
            textRounder = function (value) {
                return parseFloat(value).toFixed(2);
            };
        }

        // Data for building the clip wave area.
        const data = [];
        for (let i = 0; i <= 40 * waveClipCount; i++) {
            data.push({x: i / (40 * waveClipCount), y: (i / (40))});
        }

        // Scales for drawing the outer circle.
        const gaugeCircleX = d3.scaleLinear().range([0, 2 * Math.PI]).domain([0, 1]);
        const gaugeCircleY = d3.scaleLinear().range([0, radius]).domain([0, radius]);

        // Scales for controlling the size of the clipping path.
        const waveScaleX = d3.scaleLinear().range([0, waveClipWidth]).domain([0, 1]);
        const waveScaleY = d3.scaleLinear().range([0, waveHeight]).domain([0, 1]);

        // Scales for controlling the position of the clipping path.
        const waveRiseScale = d3.scaleLinear()
            // The clipping area size is the height of the fill circle + the wave height, so we position the clip wave
            // such that the it will overlap the fill circle at all when at 0%, and will totally cover the fill
            // circle at 100%.
            .range([(fillCircleMargin + fillCircleRadius * 2 + waveHeight), (fillCircleMargin - waveHeight)])
            .domain([0, 1]);
        const waveAnimateScale = d3.scaleLinear()
            .range([0, waveClipWidth - fillCircleRadius * 2-1.5*circleThickness]) // Push the clip area one full wave then snap back.
            .domain([0, 1]);

        // Scale for controlling the position of the text within the gauge.
        const textRiseScaleY = d3.scaleLinear()
            .range([fillCircleMargin + fillCircleRadius * 2, (fillCircleMargin + textPixels * 0.7)])
            .domain([0, 1]);

        // Center the gauge within the parent SVG.
        const gaugeGroup = gauge.append("g")
            .attr('transform', 'translate(' + locationX + ',' + locationY + ')');
        var r = 30;
        // Draw the outer circle.
        const gaugeCircleArc = d3.arc()
            .startAngle(gaugeCircleX(0))
            .endAngle(gaugeCircleX(1))
            .outerRadius(gaugeCircleY(radius))
            .innerRadius(gaugeCircleY(radius - circleThickness));
        var roundedRectExt = function(){
            return "M"+(r)+","+(circleThickness/2)+" h"+(radius+radius-r-r)+" a"+r+","+r+" 0 0 1 "+r+","+r+" v"+(radius+radius-r-r)+" a"+r+","+r+" 0 0 1 -"+r+","+r+" h-"+(radius+radius-r-r)+" a"+r+","+r+" 0 0 1 -"+r+",-"+r+" v-"+(radius+radius-r-r)+" a"+r+","+r+" 0 0 1 "+r+",-"+r+" "
        }
        gaugeGroup.append("path")
            .attr("d", roundedRectExt)
            .style("fill", "none")
            .style("stroke", config.circleColor)
            .style("stroke-width", circleThickness)
        //.attr('transform','translate('+(radius-r)+','+(radius-r)+')');

        // Text where the wave does not overlap.
        const text1 = gaugeGroup.append("text")
            .text(textRounder(textStartValue) + percentText)
            .attr("class", "liquidFillGaugeText")
            .attr("text-anchor", "middle")
            .attr("font-size", textPixels + "px")
            .style("fill", config.textColor)
            .attr('transform', 'translate(' + radius + ',' + textRiseScaleY(config.textVertPosition) + ')');
        let text1InterpolatorValue = textStartValue;


        // The clipping wave area.
        const clipArea = d3.area()
            .x(function (d) {
                return waveScaleX(d.x);
            })
            .y0(function (d) {
                return waveScaleY(Math.sin(Math.PI * 2 * config.waveOffset * -1 + Math.PI * 2 * (1 - config.waveCount) + d.y * 2 * Math.PI));
            })
            .y1(function (d) {
                return (fillCircleRadius*2.5 + waveHeight);
            });
        const waveGroup = gaugeGroup.append("defs")
            .append("clipPath")
            .attr("id", "clipWave" + elementId);
        const wave = waveGroup.append("path")
            .datum(data)
            .attr("d", clipArea)
            .attr("T", 0);

        // The inner circle with the clipping wave attached.
        const fillCircleGroup = gaugeGroup.append("g")
            .attr("clip-path", "url(#clipWave" + elementId + ")");
        fillCircleGroup.append("rect")
            .attr("x", 1.5*circleThickness)
            .attr("y", 2*circleThickness)
            .attr("rx", r - circleThickness)
            .attr("width", fillCircleRadius*2+circleThickness)
            .attr("height", fillCircleRadius*2+circleThickness)
            .style("fill", config.waveColor);

        // Text where the wave does overlap.
        const text2 = fillCircleGroup.append("text")
            .text(textRounder(textStartValue) + percentText)
            .attr("class", "liquidFillGaugeText")
            .attr("text-anchor", "middle")
            .attr("font-size", textPixels + "px")
            .style("fill", config.waveTextColor)
            .attr('transform', 'translate(' + radius + ',' + textRiseScaleY(config.textVertPosition) + ')');
        let text2InterpolatorValue = textStartValue;

        // Make the value count up.
        if (config.valueCountUp) {
            text1.transition()
                .duration(config.waveRiseTime)
                .tween("text", function () {
                    const i = d3.interpolateNumber(text1InterpolatorValue, textFinalValue);
                    return (t) => {
                        text1InterpolatorValue = textRounder(i(t));
                        // Set the gauge's text with the new value and append the % sign
                        // to the end
                        text1.text(text1InterpolatorValue + percentText);
                    }
                }).on("end", function () {
                config.valueCountUp = false;
                });
            text2.transition()
                .duration(config.waveRiseTime)
                .tween("text", function () {
                    const i = d3.interpolateNumber(text2InterpolatorValue, textFinalValue);
                    return (t) => {
                        text2InterpolatorValue = textRounder(i(t));
                        // Set the gauge's text with the new value and append the % sign
                        // to the end
                        text2.text(text2InterpolatorValue + percentText);
                    }
                });
        }

        // Make the wave rise. wave and waveGroup are separate so that horizontal and vertical movement can be controlled independently.
        const waveGroupXPosition = fillCircleMargin + fillCircleRadius * 2 - waveClipWidth +circleThickness;
        if (config.waveRise) {
            waveGroup.attr('transform', 'translate(' + waveGroupXPosition + ',' + waveRiseScale(0) + ')')
                .transition()
                .duration(config.waveRiseTime)
                .attr('transform', 'translate(' + waveGroupXPosition + ',' + waveRiseScale(fillPercent) + ')')
                .on("start", function () {
                    wave.attr('transform', 'translate(1,0)');

                }); // This transform is necessary to get the clip wave positioned correctly when waveRise=true and waveAnimate=false. The wave will not position correctly without this, but it's not clear why this is actually necessary.
        } else {
            waveGroup.attr('transform', 'translate(' + waveGroupXPosition + ',' + waveRiseScale(fillPercent) + ')');
        }

        if (config.waveAnimate) animateWave();

        function animateWave() {
            wave.attr('transform', 'translate(' + waveAnimateScale(wave.attr('T')) + ',0)');
            wave.transition()
                .duration(config.waveAnimateTime * (1 - wave.attr('T')))
                .ease(d3.easeLinear)
                .attr('transform', 'translate(' + waveAnimateScale(1) + ',0)')
                .attr('T', 1)
                .on('end', function () {
                    wave.attr('T', 0);
                    animateWave(config.waveAnimateTime);
                    config.waveRise=false
                });
        }


        function GaugeUpdater() {
            this.update = function (value) {
                var newFinalValue = parseFloat(value).toFixed(2);
                var textRounderUpdater = function (value) {
                    return Math.round(value);
                };
                if (parseFloat(newFinalValue) !== parseFloat(textRounderUpdater(newFinalValue))) {
                    textRounderUpdater = function (value) {
                        return parseFloat(value).toFixed(1);
                    };
                }
                if (parseFloat(newFinalValue) !== parseFloat(textRounderUpdater(newFinalValue))) {
                    textRounderUpdater = function (value) {
                        return parseFloat(value).toFixed(2);
                    };
                }

                var textTween = function () {
                    var i = d3.interpolate(this.textContent, parseFloat(value).toFixed(2));
                    return function (t) {
                        this.textContent = textRounderUpdater(i(t)) + percentText;
                    }
                };

                text1.transition()
                    .duration(config.waveRiseTime)
                    .tween("text", textTween);
                text2.transition()
                    .duration(config.waveRiseTime)
                    .tween("text", textTween);

                var fillPercent = Math.max(config.minValue, Math.min(config.maxValue, value)) / config.maxValue;
                var waveHeight = fillCircleRadius * waveHeightScale(fillPercent * 100);
                var waveRiseScale = d3.scaleLinear()
                    // The clipping area size is the height of the fill circle + the wave height, so we position the clip wave
                    // such that the it will overlap the fill circle at all when at 0%, and will totally cover the fill
                    // circle at 100%.
                    .range([(fillCircleMargin + fillCircleRadius * 2 + waveHeight), (fillCircleMargin - waveHeight)])
                    .domain([0, 1]);
                var newHeight = waveRiseScale(fillPercent);
                var waveScaleX = d3.scaleLinear().range([0, waveClipWidth]).domain([0, 1]);
                var waveScaleY = d3.scaleLinear().range([0, waveHeight]).domain([0, 1]);
                var newClipArea;
                if (config.waveHeightScaling) {
                    newClipArea = d3.svg.area()
                        .x(function (d) {
                            return waveScaleX(d.x);
                        })
                        .y0(function (d) {
                            return waveScaleY(Math.sin(Math.PI * 2 * config.waveOffset * -1 + Math.PI * 2 * (1 - config.waveCount) + d.y * 2 * Math.PI));
                        })
                        .y1(function (d) {
                            return (fillCircleRadius * 2.5+ waveHeight);
                        });
                } else {
                    newClipArea = clipArea;
                }

                var newWavePosition = config.waveAnimate ? waveAnimateScale(1) : 0;
                wave.transition()
                    .duration(0)
                    .transition()
                    .duration(config.waveAnimate ? (config.waveAnimateTime * (1 - wave.attr('T'))) : (config.waveRiseTime))
                    .ease('linear')
                    .attr('d', newClipArea)
                    .attr('transform', 'translate(' + newWavePosition + ',0)')
                    .attr('T', '1')
                    .each("end", function () {
                        if (config.waveAnimate) {
                            wave.attr('transform', 'translate(' + waveAnimateScale(0) + ',0)');
                            animateWave(config.waveAnimateTime);
                        }
                    });
                waveGroup.transition()
                    .duration(config.waveRiseTime)
                    .attr('transform', 'translate(' + waveGroupXPosition + ',' + newHeight + ')')
            }
        }

        let batteryWidth = 50;
        let barWidth = 52;
        let batteryHeight = 25;
        let rightArcRadius = 10;
        let circleArcRadius = r - circleThickness;
        if(bat !=undefined && bat>0){
            var batteryRectExt = function(){
                return "M0"+(2*radius - r-barWidth - 0.5* circleThickness +circleArcRadius)+","+(2*circleThickness)+" h"+(barWidth-circleArcRadius)+" a"+circleArcRadius+","+circleArcRadius+" 0 0 1 "+circleArcRadius+","+circleArcRadius+" v"+(batteryHeight-circleArcRadius)+"  h-"+(barWidth-(rightArcRadius))+" a"+(rightArcRadius)+","+(rightArcRadius)+" 0 0 1 -"+(rightArcRadius)+",-"+(rightArcRadius)+" v"+(-(batteryHeight+batteryHeight-(3/4*r)-(3/4*r)-circleThickness))+" z"
            }
            gaugeGroup.append("path")
                .attr("d", batteryRectExt)
                .style("fill",  "#84a8d1")
                .style("stroke", "#84a8d1")
                .style("stroke-width", 0)
            //.attr('transform','translate('+(radius-r)+','+(radius-r)+')');
            let img = require("../../icons/bat4.svg")
            if(bat<70){
                img = require("../../icons/bat3.svg");
            }
            if(bat<50){
                img = require("../../icons/bat2.svg");
            }
            if(bat<40){
                img = require("../../icons/bat1.svg");
            }
            if(bat<30){
                img = require("../../icons/bat0.svg");
            }

            gaugeGroup.append("svg:image")
                .attr("xlink:href", img)
                .attr("x", 2* radius - (circleThickness + batteryWidth ) - 3)
                .attr("y",  1.5* circleThickness+ batteryHeight*0.35)
                .attr("width", 1.5*batteryHeight)
                .attr("height", batteryHeight*0.65);

        }

        //end battery
        if(strength != undefined){
            var signalRectExt = function(){
                return "M0"+(1.5*circleThickness+rightArcRadius)+","+(circleThickness*2)+" h"+(barWidth-(rightArcRadius))+"  v "+(batteryHeight - rightArcRadius) + " a"+(rightArcRadius)+","+(rightArcRadius)+" 0 0 1 -"+(rightArcRadius)+", "+(rightArcRadius)+" h-"+(barWidth-rightArcRadius)+"  v-"+(batteryHeight-circleArcRadius)+" a"+(circleArcRadius)+","+(circleArcRadius)+" 0 0 1 "+(circleArcRadius)+",-"+(circleArcRadius)+"  z"
            }
            gaugeGroup.append("path")
                .attr("d", signalRectExt)
                .style("fill",  "#84a8d1")
                .style("stroke", "#84a8d1")
                .style("stroke-width", 0)
            //.attr('transform','translate('+(radius-r)+','+(radius-r)+')');
            //signal strength image
            let strImg = require("../../icons/str3.svg");
            let str = Math.abs(strength)
            if(str>85){
                strImg = require("../../icons/str3.svg");
            }
            if(str>=90){
                strImg = require("../../icons/str2.svg");
            }
            if(str>=92){
                strImg = require("../../icons/str1.svg");
            }
            if(str>=94){
                strImg = require("../../icons/str0.svg");
            }
            gaugeGroup.append("svg:image")
                .attr("xlink:href", strImg)
                .attr("x", (circleThickness))
                .attr("y",  1.5* circleThickness  +batteryHeight*0.3)
                .attr("width", 2.5*batteryHeight)
                .attr("height", batteryHeight*0.65);

        }
        return new GaugeUpdater();
    }

    shouldComponentUpdate(){
        return this.state == undefined || this.props.percentage !== this.state.data.percentage;
    }

    componentDidMount(){
        this.setState({data: {percentage: this.props.percentage}})
        window.addEventListener("resize", this.setWindowWidth);
        this.drawCircle();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setWindowWidth);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.drawCircle();
    }

    drawCircle = () => {
        let svgElement = d3.select(this.refs[this.props.sensor]);
        var config1 = this.conf;
        config1.circleColor = "#0951a3";
        config1.textColor = "#063872";
        config1.waveTextColor = "#6b96c7";
        config1.waveColor = "#2162ac";
        config1.waveAnimateTime = 2000;
        // console.log(JSON.stringify(this.props));
        this.loadLiquidFillGauge(svgElement, Number.isNaN(this.props.percentage)?0:this.props.percentage, config1, this.props.sensor, this.props.batteryLevel, this.props.strength)
        // config1.waveRise=false;
        // config1.valueCountUp = false;8
    }

    render() {
        const circleStyle = {
            width: "100%",
            height: "100%"
        }

        return (<div style={{maxHeight: "500px", width: "100%", height: "100%"}}>
            <svg ref={this.props.sensor} style={circleStyle} id={this.props.sensor}></svg>
            </div>
        );
    }


    setWindowWidth = () => {
        this.drawCircle();
    }
}

