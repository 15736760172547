import React from "react";
// react plugin for creating charts
// @material-ui/core
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
// import {subscribe} from 'mqtt-react';
import {connect} from 'react-redux'
// import { saveQueue } from "./actions";

// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {store} from "../../store";
import {saveQueue, userFetched} from "../../actions";
import SensorHistory from "../../components/SensorHistory/SensorHistory";


const parse = function (message) {
    try {
        var item = JSON.parse(message);
        return item;
    } catch (e) {
        return message.toString();
    }
}

class _History extends React.Component {

    constructor(props, context) {
        super(props, context);
    this.state = {sensors: {}};
    }

//
    username;
    password;
    full_name;

    render() {
        if (this.props.username !== undefined) {
            return (
                <div>
                    <GridContainer  >
                        <SensorHistory sensors={this.props.queue.queue} />
                    </GridContainer>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        username: state.user.username,
        password: state.user.password,
        queue: state.queue
    }
};
const mapDispatchToProps = {userFetched: userFetched, saveQueue: saveQueue()}; // (2)

const History = connect(mapStateToProps, mapDispatchToProps)(_History); // (3)

export default History;


