import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import styles from "assets/jss/material-dashboard-react/components/cardBodyStyle.js";

const useStyles = makeStyles(styles);

export default function FlatCardBody(props) {
  const classes = useStyles();
  const { className, children, plain, profile, ...rest } = props;
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyProfile]: profile,
    [className]: className !== undefined
  });
  const ref = React.useRef(null);

  React.useEffect(() => {
        // Zaktualizuj tytuł dokumentu korzystając z interfejsu API przeglądarki
      function handleResize() {
          if( ref.current!= null) {
              ref.current.style.height = Math.min(500, ref.current.clientWidth) + "px";
          }
      }

      window.addEventListener('resize', handleResize)
      if( ref.current!= null) {
          ref.current.style.height = Math.min(500, ref.current.clientWidth) + "px";
      }

      }
  );

  return (
    <div ref={ref} className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
}

FlatCardBody.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  children: PropTypes.node
};
