import React from "react";
import * as d3 from 'd3';
// import {instanceOf} from "prop-types";
// import {withStyles} from '@material-ui/styles';
import "./styles.css"
import Tooltip from "../Tooltip";
import BatteryTooltip from "../BatteryTooltip";

var locales = {
    "decimal": ".",
    "thousands": ",",
    "grouping": [3],
    "currency": ["$", ""],
    "dateTime": "%a %b %e %X %Y",
    "date": "%m/%d/%Y",
    "time": "%H:%M:%S",
    "periods": ["AM", "PM"],
    "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    "shortDays": ["Sun", "Mon", "Tue", "Wed", "Czw", "Fri", "Sat"],
    "months": ["January", "February", "March", "April", "May", "June", "July", "August", "Września", "October", "November", "December"],
    "shortMonths": ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"]
};
d3.timeFormatDefaultLocale(locales);

class AxisX extends React.Component {
    render() {
        var data = this.props.data;
        var margin = this.props.margin;
        var height = this.props.height - margin.top - margin.bottom - xLabelHeight;
        var width = this.props.width - margin.left - margin.right;

        var x = d3.scaleTime()
            .range([0, width]);

        var xAxis = d3.axisBottom(x).ticks(7).tickFormat(d3.timeFormat("%d %b %H:%M"));
        x.domain(d3.extent(data, function (d) {
            return d.t;
        }));

        // this.setState({X: x});

        function make_x_gridlines() {
            return d3.axisBottom(x)
                .ticks(5)
        }

        let select = d3.select("#" + this.props.sensor).select(".x");
        select.attr("transform", "translate(0," + height + ")").call(xAxis)
            .selectAll("text").attr("y", 0)
            .attr("x", -10)
            .attr("dy", "5").attr("dx", "0").attr("transform", "rotate(270)")
            .style("text-anchor", "end");

        select.append("g")
            .attr("class", "grid")
            .attr("transform", "translate(0," + 0 + ")")
            .call(make_x_gridlines()
                .tickSize(-height)
                .tickFormat("")
            )
        return (
            <g className="x axis"></g>
        );
    }
}

class AxisY extends React.Component {
    render() {
        // var data = this.props.data;
        var margin = this.props.margin;
        var height = this.props.height - margin.top - margin.bottom - xLabelHeight;
        var width = this.props.width - margin.left - margin.right;

        var y = d3.scaleLinear()
            .range([height, 0]);

        var yAxis = d3.axisLeft(y);

        y.domain([0, 100]);

        // this.setState({y: y});

        function make_y_gridlines() {
            return d3.axisLeft(y)
                .ticks(5)
        }

        d3.select("#" + this.props.sensor).select(".y").call(yAxis)
            .append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 2)
            .attr("dy", ".71em")
            .style("text-anchor", "end")
            .text("Price ($)");
        d3.select("#" + this.props.sensor).select(".y").append("g")
            .attr("class", "grid")
            .call(make_y_gridlines()
                .tickSize(-width)
                .tickFormat("")
            )
        return (
            <g className="y"></g>
        );
    }
}

class AxisRightY extends React.Component {
    render() {
        // var data = this.props.data;
        var margin = this.props.margin;
        var height = this.props.height - margin.top - margin.bottom - xLabelHeight;
        var width = this.props.width - margin.left - margin.right;

        var y = d3.scaleLinear()
            .range([height, 10]);

        var yAxis = d3.axisRight(y);

        y.domain(d3.extent(this.props.data, function (d) {
            return d.m
        }));

        // this.setState({y: y});

        function make_y_gridlines() {
            return d3.axisRight(y)
                .ticks(5)
        }

        d3.select("#" + this.props.sensor).select(".y2").attr("transform", "translate(" + width + ",0)").call(yAxis)
            .append("text")
            .attr("x", 20)
            .attr("dy", ".71em")
            .attr("dx", "-10em")
            // .style("text-anchor", "end")
            .text("Price ($)");
        d3.select("#" + this.props.sensor).select(".y2").append("g")
            .attr("class", "grid")
            .call(make_y_gridlines()
                .tickSize(-width)
                .tickFormat("")
            )
        return (
            <g className="y2"></g>
        );
    }
}

class Area extends React.Component {

    render() {
        var data = this.props.data;
        var margin = this.props.margin;
        var height = this.props.height - margin.top - margin.bottom - xLabelHeight;
        var width = this.props.width - margin.left - margin.right;

        var x = d3.scaleTime()
            .range([0, width]);

        var y = d3.scaleLinear()
            .range([height, 0]);

        // data.forEach(function(d) {
        x.domain(d3.extent(data, function (d) {
            return d.t;
        }));
        y.domain([0, 100]);

        var area = d3.area()
            .x(function (d) {
                return x(d.t);
            })
            .y0(height)
            .y1(function (d) {
                return y(d.r);
            });


        // console.log(data);
        //


        var newline = area(data);
        return (
            !Number.isNaN(height) ?
                <path className="area" d={newline}></path>
                : null
        );
    }
}

class Dots extends React.Component {

    render() {

        // const props = this.props;

        var data = this.props.data;
        // var sensorProps = this.props.sensor;
        var margin = this.props.margin;
        var height = this.props.height - margin.top - margin.bottom - xLabelHeight;
        var width = this.props.width - margin.left - margin.right;
        var x = d3.scaleTime().range([0, width]);
        var y = d3.scaleLinear().range([height, 0]);
        x.domain(d3.extent(data, function (d) {
            return d.t;
        }));
        y.domain([0, 100]);

        let renderCircles = this.props.data.map((coords, i) => (
            <circle
                cx={Number.isNaN(x(coords.t)) ? 0 : x(coords.t)}
                cy={Number.isNaN(y(coords.r)) ? 0 : y(coords.r)}
                r="1.25"
                style={{fill: "#2e2461"}}
                key={i}
                onMouseOver={() => this.props.onMouseOverCallback(coords)}
                onMouseOut={() => this.props.onMouseOutCallback(null)}
            />
        ))

        return <g>{renderCircles}</g>
    }
}

class BatteryDots extends React.Component {

    render() {

        // const props = this.props;

        var data = this.props.data;
        // var sensorProps = this.props.sensor;
        var margin = this.props.margin;
        var height = this.props.height - margin.top - margin.bottom - xLabelHeight;
        var width = this.props.width - margin.left - margin.right;
        var x = d3.scaleTime().range([0, width]);
        var y = d3.scaleLinear().range([height, 0]);
        x.domain(d3.extent(data, function (d) {
            return d.t;
        }));
        y.domain([3050, 3800]);

        let renderCircles = this.props.data.map((coords, i) => (
            <circle
                cx={Number.isNaN(x(coords.t)) ? 0 : x(coords.t)}
                cy={Number.isNaN(y(coords.r)) ? 0 : y(coords.b)}
                r="1.25"
                style={{fill: "#8d2c2c"}}
                key={i}
                onMouseOver={() => this.props.onMouseOverCallback(coords)}
                onMouseOut={() => this.props.onMouseOutCallback(null)}
            />
        ))

        return <g>{renderCircles}</g>
    }
}

class HumidityDots extends React.Component {

    render() {

        // const props = this.props;

        var data = this.props.data;
        // var sensorProps = this.props.sensor;
        var margin = this.props.margin;
        var height = this.props.height - margin.top - margin.bottom - xLabelHeight;
        var width = this.props.width - margin.left - margin.right;
        var x = d3.scaleTime().range([0, width]);
        var y = d3.scaleLinear().range([height, 0]);
        x.domain(d3.extent(data, function (d) {
            return d.t;
        }));
        y.domain([0, 100]);

        let renderCircles = this.props.data.map((coords, i) => (
            <circle
                cx={Number.isNaN(x(coords.t)) ? 0 : x(coords.t)}
                cy={Number.isNaN(y(coords.r)) ? 0 : y(coords.h)}
                r="1.25"
                style={{fill: "green"}}
                key={i}
                onMouseOver={() => this.props.onMouseOverCallback(coords)}
                onMouseOut={() => this.props.onMouseOutCallback(null)}
            />
        ))

        return <g>{renderCircles}</g>
    }
}

class TemperatureDots extends React.Component {

    render() {

        // const props = this.props;

        var data = this.props.data;
        // var sensorProps = this.props.sensor;
        var margin = this.props.margin;
        var height = this.props.height - margin.top - margin.bottom - xLabelHeight;
        var width = this.props.width - margin.left - margin.right;
        var x = d3.scaleTime().range([0, width]);
        var y = d3.scaleLinear().range([height, 0]);
        x.domain(d3.extent(data, function (d) {
            return d.t;
        }));
        y.domain(d3.extent(data, function (d) {
            return d.m;
        }));

        let renderCircles = this.props.data.map((coords, i) => (
            <circle
                cx={Number.isNaN(x(coords.t)) ? 0 : x(coords.t)}
                cy={Number.isNaN(y(coords.r)) ? 0 : y(coords.m)}
                r="1.25"
                style={{fill: "orange"}}
                key={i}
                onMouseOver={() => this.props.onMouseOverCallback(coords)}
                onMouseOut={() => this.props.onMouseOutCallback(null)}
            />
        ))

        return <g>{renderCircles}</g>
    }
}

const xLabelHeight = 40;

class LineBattery extends React.Component {

    render() {
        var data = this.props.data;
        //Math.max.apply(Math, data.map(function(o) { return o.b; }))
        var margin = this.props.margin;
        var height = this.props.height - margin.top - margin.bottom - xLabelHeight;
        var width = this.props.width - margin.left - margin.right;

        var x = d3.scaleTime()
            .range([0, width]);

        var y = d3.scaleLinear()
            .range([height, 0]);

        // data.forEach(function(d) {
        x.domain(d3.extent(data, function (d) {
            //         // console.log(d.t)
            return d.t;
            //         // console.log("ggg-> " +timeFormat1)
            //         // return  d.t;
        }));
        y.domain([3050, 3800]);
        // });
        // console.log(data)

        var line = d3.line()
            .x(function (d) {
                return x(d.t);
            })
            .y(function (d) {
                return y(Math.max(3050, Math.min(d.b, 3800)));
            })

        ;

        // console.log(data);
        //


        var newline = line(data);

        return (
            !Number.isNaN(height) ?
                (<path className="lineBattery" d={newline}></path>)
                : null
        );
    }
}

class LineHumidity extends React.Component {

    render() {
        var data = this.props.data;
        //Math.max.apply(Math, data.map(function(o) { return o.b; }))
        var margin = this.props.margin;
        var height = this.props.height - margin.top - margin.bottom - xLabelHeight;
        var width = this.props.width - margin.left - margin.right;

        var x = d3.scaleTime()
            .range([0, width]);

        var y = d3.scaleLinear()
            .range([height, 0]);

        // data.forEach(function(d) {
        x.domain(d3.extent(data, function (d) {
            //         // console.log(d.t)
            return d.t;
            //         // console.log("ggg-> " +timeFormat1)
            //         // return  d.t;
        }));
        y.domain([0, 100]);
        // });
        // console.log(data)

        var line = d3.line()
            .x(function (d) {
                return x(d.t);
            })
            .y(function (d) {
                return y(d.h);
            })

        ;

        // console.log(data);
        //


        var newline = line(data);

        return (
            !Number.isNaN(height) ?
                (<path className="lineHumidity" d={newline}></path>)
                : null
        );
    }
}

class LineTemperature extends React.Component {

    render() {
        var data = this.props.data;
        //Math.max.apply(Math, data.map(function(o) { return o.b; }))
        var margin = this.props.margin;
        var height = this.props.height - margin.top - margin.bottom - xLabelHeight;
        var width = this.props.width - margin.left - margin.right;

        var x = d3.scaleTime()
            .range([0, width]);

        var y = d3.scaleLinear()
            .range([height, 0]);

        // data.forEach(function(d) {
        x.domain(d3.extent(data, function (d) {
            return d.t;
        }));
        y.domain(d3.extent(data, function (d) {
            return d.m;
        }));

        var line = d3.line()
            .x(function (d) {
                return x(d.t);
            })
            .y(function (d) {
                return y(d.m);
            })

        ;

        // console.log(data);
        //


        var newline = line(data);

        return (
            !Number.isNaN(height) ?
                (<path className="lineTemperature" d={newline}></path>)
                : null
        );
    }
}

class Line extends React.Component {

    render() {
        var data = this.props.data;
        var margin = this.props.margin;
        var height = this.props.height - margin.top - margin.bottom - xLabelHeight;
        var width = this.props.width - margin.left - margin.right;

        var x = d3.scaleTime()
            .range([0, width]);

        var y = d3.scaleLinear()
            .range([height, 0]);

        // data.forEach(function(d) {
        x.domain(d3.extent(data, function (d) {
            //         // console.log(d.t)
            return d.t;
            //         // console.log("ggg-> " +timeFormat1)
            //         // return  d.t;
        }));
        y.domain([0, 100]);
        // });
        // console.log(data)

        var line = d3.line()
            .x(function (d) {
                return x(d.t);
            })
            .y(function (d) {
                return y(d.r);
            })

        ;

        // console.log(data);
        //


        var newline = line(data);

        return (
            !Number.isNaN(height) ?
                (<path className="line" d={newline}></path>)
                : null
        );
    }
}


export default class Chart extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            graph: "",
            container: "",
            chartWidth: 0,
            chartHeight: 0,
            x: NaN,
            y: NaN,
            data: [],
            margin: {}
        };
    }

    getChartSize(el) {
        let width = .9 * parseInt(el.style('width'));
        let height = .7 * parseInt(el.style('height'));

        return [width, height];
    }

    // getInitialState() {
    //     return {
    //         graph: "",
    //         container: "",
    //         chartWidth: 0,
    //         chartHeight: 0,
    //         x: NaN,
    //         y: NaN,
    //         data: [],
    //         margin: {}
    //     }
    // }

    resize = () => {
        const container = this.state.container;
        let chartWidth = (container.select(function () {
            return this.parentNode;
        })._groups[0][0].clientWidth - 0);
        let chartHeight = (container.select(function () {
            return this.parentNode;
        })._groups[0][0].clientHeight - 10);
        // let chartWidth = this.getChartSize(container)[0];
        // let chartHeight = this.getChartSize(container)[1];

        this.setState({
            chartWidth: chartWidth,
            chartHeight: chartHeight,
        })

    }

    componentDidMount() {
        // this.resize.bind(this.state);
        //  const graph = d3.select("#chart_" + this.props.sensor.sensor);

        const container = d3.select("#graphic_" + this.props.sensor.sensor);
        window.addEventListener('resize', this.resize);

        const margin = {top: 20, right: 20, bottom: 30, left: 30};

        // var containerBB = container.node().getBoundingClientRect();

        // var graphBB = graph.node().getBoundingClientRect();

        // let chartWidth = this.  getChartSize(container)[0];
        // let chartHeight = this.getChartSize(container)[1];
        let chartWidth = container.select(function () {
            return this.parentNode;
        })._groups[0][0].clientWidth - 0;
        let chartHeight = container.select(function () {
            return this.parentNode;
        })._groups[0][0].clientHeight - 10;

        const _this = this;

        // function type(d) {
        //     d.t = formatDate.parse(d.t);
        //     d.r = +d.r;
        //     return d;
        // }

        // d3.tsv("data.tsv", type, function (error, data) {
        //     if (error) throw error;
        //
        _this.setState({
            // graph: graph,
            container: container,
            chartWidth: chartWidth,
            chartHeight: chartHeight,
            data: this.props.data,
            batteryEnabled: this.props.battery,
            margin: margin
        });
        // });     }
    }

    render() {
        var width = this.state.chartWidth;
        var height = this.state.chartHeight;
        var margin = this.state.margin;
        var data = this.props.data;
        let formatDate = d3.timeParse("%Y-%m-%dT%H:%M:%S%Z");
        const sensor = this.props.sensor;
        let maxLevel = sensor.maxLevel

        if (sensor.maxLevel == undefined) {
            maxLevel = Math.max.apply(null, data.map(function (value) {
                return value.r
            }));
        }
        if (sensor.maxLevelManual != undefined && !isNaN(sensor.maxLevelManual)) {
            maxLevel = sensor.maxLevelManual;
        }
        let fullLevel = sensor.fullLevel;
        if (fullLevel == undefined) {
            fullLevel = 10;
            ;
        }
        data.forEach(function (d) {
            if (!(d.t instanceof Date)) {
                d.t = formatDate(d.t)
                d.raw = d.r;
                if (d.p != undefined) {
                    d.r = d.p;
                } else {
                    d.r = Math.max(0, Math.min((maxLevel - d.raw) / (maxLevel - fullLevel * 100) * 100, 100));
                }
                // console.log(d.r);
            }
        });
        // data.sort(function(a,b){
        //     return new Date(b.t) - new Date(a.t);
        // });

        return (
            <div id={"graphic_" + this.props.sensor.sensor} style={{width: "100%", "min-height": "500px"}}>
                <svg height={height} width={(width - 50)} id={"chart_" + this.props.sensor.sensor}>
                    <g transform="translate(30,20)">
                        <AxisX width={width - 50} height={height} margin={margin} data={data}
                               sensor={"chart_" + this.props.sensor.sensor}/>
                        <AxisY width={width - 50} height={height} margin={margin} data={data}
                               sensor={"chart_" + this.props.sensor.sensor}/>
                        {this.isTempVisible() == true ?
                            <AxisRightY width={width - 50} height={height} margin={margin} data={data}
                                        sensor={"chart_" + this.props.sensor.sensor}/>
                            : null}
                        {/*<AxisRightY width={width} height={height} margin={margin} data={data}*/}
                        {/*       sensor={"chart_" + this.props.sensor.sensor}/>*/}
                        <Area width={width - 50} height={height} margin={margin} data={data}
                              sensor={"chart_" + this.props.sensor.sensor}/>

                        <Line width={width - 50} height={height} margin={margin} data={data}
                              sensor={"chart_" + this.props.sensor.sensor}/>

                        {(this.isBatteryVisible() == 'true') ?
                            <LineBattery width={width - 50} height={height} margin={margin} data={data}
                                         sensor={"chart_" + this.props.sensor.sensor}/>
                            : null}
                        {this.isTempVisible() == true ?
                            <LineHumidity width={width - 50} height={height} margin={margin} data={data}
                                          sensor={"chart_" + this.props.sensor.sensor}/>
                            : null}
                        {this.isTempVisible() == true ?
                            <LineTemperature width={width - 50} height={height} margin={margin} data={data}
                                             sensor={"chart_" + this.props.sensor.sensor}/>
                            : null}
                        <Dots width={width - 50} height={height} margin={margin} data={data}
                              sensor={"chart_" + this.props.sensor.sensor}
                              onMouseOverCallback={datum => this.setState({tooltip: datum})}
                              onMouseOutCallback={datum => this.setState({tooltip: null})}/>
                        {this.isBatteryVisible() == 'true' ?
                            <BatteryDots width={width - 50} height={height} margin={margin} data={data}
                                         sensor={"chart_" + this.props.sensor.sensor}
                                         onMouseOverCallback={datum => this.setState({batteryTooltip: datum})}
                                         onMouseOutCallback={datum => this.setState({batteryTooltip: null})}/>
                            : null}
                        {this.isTempVisible() == true ?
                            <HumidityDots width={width - 50} height={height} margin={margin} data={data}
                                          sensor={"chart_" + this.props.sensor.sensor}
                                          onMouseOverCallback={datum => null}
                                          onMouseOutCallback={datum => null}/>
                            : null}
                        {this.isTempVisible() == true ?
                            <TemperatureDots width={width - 50} height={height} margin={margin} data={data}
                                             sensor={"chart_" + this.props.sensor.sensor}
                                             onMouseOverCallback={datum => null}
                                             onMouseOutCallback={datum => null}/>
                            : null}


                    </g>
                </svg>
                <div>
                    <div style={{width: "30px", height: "20px", border: "1px solid", float: "left", margin: "2px 10px 2px 10px", backgroundColor: "rgba(8,72,146,0.25)"}} /><div style={{float: "left"}}> odczyt </div>
                    {this.isBatteryVisible() == 'true' ?
                    <div style={{width: "30px", height: "20px", border: "1px solid", float: "left", margin: "2px 10px 2px 10px", backgroundColor: "red"}} />
                        :null}
                    {this.isBatteryVisible() == 'true' ?
                        <div style={{float: "left"}}> poziom baterii </div>
                        :null}
                    {this.isTempVisible() == true ?
                        <div style={{width: "30px", height: "20px", border: "1px solid", float: "left", margin: "2px 10px 2px 10px", backgroundColor: "green"}} />
                        :null}
                    {this.isTempVisible() == true ?
                        <div style={{float: "left"}}> wilgotność </div>
                        :null}
                    {this.isTempVisible() == true ?
                        <div style={{width: "30px", height: "20px", border: "1px solid", float: "left", margin: "2px 10px 2px 10px", backgroundColor: "orange"}} />
                        :null}
                    {this.isTempVisible() == true ?
                        <div style={{float: "left"}}> temperatura </div>
                        :null}
                </div>
                {this.state.tooltip ?
                    <Tooltip
                        hoveredBar={this.state.tooltip}
                        proops={this.props}
                        margin={margin}
                        width={width - 50}
                        height={height}
                    /> :
                    null
                }
                {this.state.batteryTooltip ?
                    <BatteryTooltip
                        hoveredBar={this.state.batteryTooltip}
                        proops={this.props}
                        margin={margin}
                        width={width - 50}
                        height={height}
                    /> :
                    null
                }
            </div>
        )
    }

    isBatteryVisible = () => {
        return localStorage.getItem("batteryHistoryToggle_" + this.props.sensor.sensor);
    }

    isTempVisible() {
        return this.props.data.length > 0 && this.props.data[0].m != undefined;
    }
}



