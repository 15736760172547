import {createStore} from 'redux';
import reducers from './reducers';
import {loadState, saveState} from 'localStore'
import throttle from 'lodash/throttle'

// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'

// const persistConfig = {
//     key: 'root',
//     storage,
// }

// const persistedReducer = persistReducer(persistConfig, reducers)

// export default () => {
//     let store = createStore(persistedReducer)
//     let persistor = persistStore(store)
//     return { store, persistor }
// }\
const persistedState = loadState()

const store = createStore(reducers, persistedState);

store.subscribe(throttle(() => {
    console.debug('saveState')
    let todos = store.getState()
    // console.log("todos " + JSON.stringify(todos.queue))
    saveState(todos)
}, 1000))

export {store};
