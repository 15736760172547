import {combineReducers} from "redux";
import {user} from "./user";
import {queue} from "./queue";
import {userValid} from "./userValid";

export default combineReducers({
    user: user,
    queue: queue,
    userValid: userValid
});
